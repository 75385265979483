const AppReducer = (state, action) => {
    switch (action.type) {
        case 'NUM_STRAIGHT_FLUSH':
            return {
                ...state,
                numStraightFlush: state.numStraightFlush + action.payload
            };
        case 'NUM_STRAIGHT':
            return {
                ...state,
                numStraight: state.numStraight + action.payload
            };
        case 'NUM_FLUSH':
            return {
                ...state,
                numFlush: state.numFlush + action.payload
            };
        case 'NUM_FOUROFAKIND':
            return {
                ...state,
                numFourOfAKind: state.numFourOfAKind + action.payload
            };
        case 'NUM_THREEOFAKIND':
            return {
                ...state,
                numThreeOfAKind: state.numThreeOfAKind + action.payload
            };
        case 'NUM_TWOPAIR':
            return {
                ...state,
                numTwoPair: state.numTwoPair + action.payload
            };
        case 'NUM_PAIR':
            return {
                ...state,
                numPair: state.numPair + action.payload
            };
        case 'NUM_HIGHCARD':
            return {
                ...state,
                numHighCard: state.numHighCard + action.payload
            };
        case 'NUM_FULLHOUSE':
            return {
                ...state,
                numFullhouse: state.numFullhouse + action.payload
            };
        case 'BESTHAND_FOR_ROUND':
            return {
                ...state,
                besthandForRound: action.payload
            };
        case 'PREVIOUS_BESTHAND':
            return {
                ...state,
                previousBesthand: action.payload
            };
        case 'SCORE':
            return {
                ...state,
                score: state.score + action.payload
            };
        case 'SCORE_UPDATE':
            return {
                ...state,
                scoreUpdate: action.payload
            };
        case 'SCORE_UPDATE_HAND':
            return {
                ...state,
                scoreUpdateHand: action.payload
            };
        case 'NUM_WRONG':
            return {
                ...state,
                numWrong: state.numWrong + action.payload
            };
        case 'NUM_RIGHT':
            return {
                ...state,
                numRight: state.numRight + action.payload
            };
        case 'MESSAGE':
            return {
                ...state,
                message: action.payload
            };
        case 'GAMEOVER':
            return {
                ...state,
                gameover: action.payload
            };
        case 'PLAY_AGAIN':
            return {
                ...state,
                // isActive:0,
                gameover: 0,
                message: '',
                numRight:0,
                numWrong:0,
                score:0,
                scoreUpdate:0,
                scoreUpdateHand:'',
                numStraightFlush:0,
                numFourOfAKind:0,
                numFullhouse:0,
                numFlush:0,
                numStraight:0,
                numThreeOfAKind:0,
                numTwoPair:0,
                numPair:0,
                numHighCard:0,
                besthandForRound:'',
                previousBesthand:'',
                gameOver:0,
                timesUp:0,
                tooManyWrong:0,
                restartTimerBool:true
            };
        // case 'TOGGLE_TIMER':
        //     return {
        //         ...state,
        //         isActive: action.payload
        //     };
        case 'GAME_OVER':
            return {
                ...state,
                gameOver: action.payload
            };
        case 'TIMES_UP':
            return {
                ...state,
                timesUp: action.payload
            };
        case 'TOO_MANY_WRONG':
            return {
                ...state,
                tooManyWrong: action.payload
            };
        case 'RESTART_TIMER_BOOL':
            return {
                ...state,
                restartTimerBool:action.payload
            }
        default: return state;
    }
}

export default AppReducer
