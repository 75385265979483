import React, { useState, useEffect,useContext } from 'react';
import {GlobalContext} from "../context/GlobalState";

// modified from source https://upmostly.com/tutorials/build-a-react-timer-component-using-hooks

const Timer = ({maxSeconds, currentLevel, numHands}) => {

    const {
        setTimesUp,
        setGameOver,
        gameOver,
        setRestartTimerBool,
        restartTimerBool
    } = useContext(GlobalContext);

    const [seconds, setSeconds] = useState(maxSeconds);
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        let interval = null;
        if (restartTimerBool) {
            setRestartTimerBool(false)
            setSeconds(maxSeconds)
            setIsActive(true)
            setGameOver(false)
        } else if (!gameOver && isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
        } else if (!gameOver && (!isActive || seconds === 0)) {
            setGameOver(true)
            setTimesUp(true)
            setIsActive(false);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds, setIsActive, setGameOver, setTimesUp, setRestartTimerBool, setSeconds, gameOver, maxSeconds, restartTimerBool]);

    return (
        <div className="timerCont" key={currentLevel + "_" + maxSeconds + "_" + numHands}>
            <div className="time">
                Seconds: {seconds}
            </div>
        </div>
    );
};

export default Timer;