import React from "react";
import { Link } from "react-router-dom";

const Intro = ({numHands, setExercise}) => {
  return (
    <div className="App" key={numHands}>
      <div className='introTextCont'>
        Hello and welcome Poker Trainer!
        {/*<br/><br/>*/}
        {/*Log in to record your scores. &nbsp;*/}
        {/*<Link*/}
        {/*    to={"/login"}*/}
        {/*    className="introButton"*/}
        {/*    >Login &raquo;</Link>*/}
        <br />
        <br />
        Ready to dive in? &nbsp;
        <Link
            to={"/train"}
            className="introButton"
        >Then get training &raquo;
        </Link>
        <br />
        <br />
        <b>Brief overview</b>
        <br/> <br />Click on the hole cards (the set of two cards) that when either alone or combined with the
        community cards make the best poker hand from the best five cards.
        <br/><br/>
        For example, in the gameplay pictured below, you would click on the 9 of Diamonds and King of Clubs to
        indicate they are the winning hole cards.
        <br/>
        <br/>
        <img
            className='introImage'
            src='https://s3.us-west-1.amazonaws.com/pokertrainer.ezcg.com/intro1.png'
            alt='game screenshot'
        />
        <br/>
        Once you click on the hole cards, your choice is evaluated as right or wrong.
        <br/>
        <br/>
        <img
            className='introImage'
            src='https://s3.us-west-1.amazonaws.com/pokertrainer.ezcg.com/intro2.png'
            alt='game screenshot'
        />
        <br/>
        You then click on 'Next Street', a new card is dealt to the community cards and you
        must evaluate the winning hole cards again.
        <br /><br />
        <img
            className='introImage'
            src='https://s3.us-west-1.amazonaws.com/pokertrainer.ezcg.com/intro3.png'
            alt='game screenshot'
        />
        <br/>
        You proceed through the streets until the river is done and you click on a
        'Next hand' button for a new hand.
        <br/>
        <br/>
        The competitive version of this game starts with two player's hole cards (as shown above) and increases
        the
        number of player's per level until there are max 10 players at the table as shown below.
        <br/>
        <br/>
        <img
            className='introImage'
            src='https://s3.us-west-1.amazonaws.com/pokertrainer.ezcg.com/intro4.png'
            alt='game screenshot'
        />
        <br/>
        Score is kept; points are
        awarded for correct answers and deducted for incorrect answers.
        <br/> <br/>
        A timer runs for each hand and as the levels increase, the amount of time per hand
        decreases.
        <br/>
        <br/>
        Once you get 6 wrong answers or don't pick a winner in time, the game ends.
        <br/>
        <br/>
        Review the <a
            rel="noopener noreferrer"
            href='https://www.pokernews.com/poker-rules/texas-holdem.htm#2-texas-hold-em-rule'
            target='blank'
        >rules</a> or the <a
            rel="noopener noreferrer"
            href='https://www.pokernews.com/poker-rules/texas-holdem.htm#3-the-hands-in-texas'
            target='_blank'
        >hand ranks</a> of Texas Hold 'em if you need to.
        <br/>
        <br/>
        Ready? Then &nbsp;
        <Link
          to={"/train"}
          className="introButton"
        >get training &raquo;
        </Link>
        <br/><br/>
        <hr />
        Train to quickly and correctly assess the variety of winning hands that occur in a real game.
        <br /><br />
        The better able you are to assess hands automatically then the better able you will be
        to focus on other things such as probability and, when live, reading other players.
        <br /><br />

        <hr/>
        TODO
        <br/>
        Save scores locally<br/>
        Save scores to db on the internet and have it tied to an online identity.<br/>
        Enable configuration of game.<br/>
        Figure out better way to handle it when hands tie<br />
        Add bonus score for time leftover in hand<br />
        Automatically progress to next hand/next street on right answer, pause time on wrong answer and
        progress on click on next button<br />
        <hr />
        To configure your game play, you may select the following options.
        <br/>
        <br/>
        Fixed number of hands at the table:
        <br/>
        Timer off:
        <br/>
        Max Wrong:
        <br/>
        <br/>
        {/*<input*/}
        {/*    type='button'*/}
        {/*    className='introButton'*/}
        {/*    onClick={() => setExercise('configured')}*/}
        {/*    value='Play Configured Version &raquo;'*/}
        {/*/>*/}
      </div>
    </div>
  )
}

export default Intro