import React, {useState} from "react";
import {GlobalProvider} from "../context/GlobalState";
import Scoreboard from "../components/scoreboard";
import GameOver from "../components/game_over";
import HandRankTally from "../components/handranktally";
import TableTrainer from '../components/table_trainer';

let Base = require('../helpers/base.js');

const Train = () => {

    let [numHands, setNumHands] = useState(1);
    let [numResets, setNumResets] = useState(0);

    function nextHand() {
        numHands++
        setNumHands(numHands);
    }

    function reset() {
        numHands = 1
        setNumHands(numHands);
        setNumResets(numResets + 1)
    }

    let currentLevel
    let foundLevel = false
    let levelArr = []
    // levelArr index is 'level'. Starts at level 1.
    // 1 numHands represents complete sequence of preflop, flop, turn, river
    // maxSeconds is total seconds covering one hand, complete sequence of preflop...river
    levelArr[1] = {minNumHands:0, maxNumHands:3, numPlayers:2,maxSeconds: 40}
    levelArr[2] = {minNumHands:levelArr[1].maxNumHands, maxNumHands:4, numPlayers:4,maxSeconds: 45}
    levelArr[3] = {minNumHands:levelArr[2].maxNumHands, maxNumHands:6, numPlayers:6,maxSeconds: 50}
    levelArr[4] = {minNumHands:levelArr[3].maxNumHands, maxNumHands:8, numPlayers:8,maxSeconds: 55}
    levelArr[5] = {minNumHands:levelArr[4].maxNumHands, maxNumHands:10, numPlayers:10,maxSeconds: 60}
    levelArr[6] = {minNumHands:levelArr[5].maxNumHands, maxNumHands:20, numPlayers:10,maxSeconds: 60}
    levelArr[7] = {minNumHands:levelArr[6].maxNumHands, maxNumHands:30, numPlayers:10,maxSeconds: 50}
    levelArr[8] = {minNumHands:levelArr[7].maxNumHands, maxNumHands:40, numPlayers:10,maxSeconds: 40}
    levelArr[9] = {minNumHands:levelArr[7].maxNumHands, maxNumHands:50, numPlayers:10,maxSeconds: 30}
    levelArr[10] = {minNumHands:levelArr[7].maxNumHands, maxNumHands:1000, numPlayers:10,maxSeconds: 20}
    for(let level in levelArr) {
        level = parseInt(level)
        let levelObj = levelArr[level]
        if (numHands >= levelObj.minNumHands && numHands < levelObj.maxNumHands) {
            currentLevel = level
            foundLevel = true
            break
        }
    }
    if (!foundLevel) {
        currentLevel = 10
    }
    let deckArr = Base.getDeckArr();
    return (
        <GlobalProvider>
        <div className="screenCont">
            <Scoreboard
                currentLevel = {currentLevel}
                numHands={numHands}
                maxSeconds={levelArr[currentLevel].maxSeconds}
            />
            <div className="cb"></div>
            <HandRankTally />
            <div key={numHands + "_" + numResets}>
                <div className="gameOverAndTableCont">
                <GameOver reset = {() => reset()} />
                <TableTrainer
                    nextHand={() => nextHand('clickwinner')}
                    deckArr = {deckArr}
                    numHands = {numHands}
                    levelObj = {levelArr[currentLevel]}
                />
                </div>
            </div>
        </div>
        </GlobalProvider>
    );
}

export default Train