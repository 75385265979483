import './App.css';
import Intro from "./components/intro";
//import Login from "./components/login";
import Train from "./components/train";
import { GlobalProvider } from './context/GlobalState';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {

  return (
    <GlobalProvider>
    <Router>
      <Switch>
        {/*<Route exact path="/login" component={Login} />*/}
        <Route exact path="/train" component={Train} />
        <Route exact path={["/", "/intro"]} component={Intro} />
      </Switch>
    </Router>
    </GlobalProvider>
  );
}

export default App;

