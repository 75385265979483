import Card from "./card";

export default function Hand({handArr, playerIndex, show}) {

  let bgClass = 'cardContOdd'
  if (typeof playerIndex === 'undefined' || playerIndex % 2 === 0) {
    bgClass = 'cardContEven'
  }

  return (<div>
  {handArr.map((cardObj, i) => {
    return <Card key={'i_' + cardObj['rank'] + '_' + cardObj['suit']}
        cardObj={cardObj}
        show={show}
        bgClass={bgClass}
    />
  })}
  </div>)

}