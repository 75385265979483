export function createDeck() {

  let max = 14;
  let deckArr = [];
  let suitArr = getSuitArr()
  for(let suit of suitArr) {
    for(let i = 2; i <= max; i++) {
      let cardObj = {rank: i, suit: suit};
      deckArr.push(cardObj);
    }
  }
  return deckArr;
}

export function getSuitArr() {
  return ['heart', 'club', 'diamond', 'spade'];
}

export function shuffle(deckArr) {
  let currentIndex = deckArr.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = deckArr[currentIndex];
    deckArr[currentIndex] = deckArr[randomIndex];
    deckArr[randomIndex] = temporaryValue;
  }

  return deckArr;

}

export function convertRankToFullName(rank) {
  switch(rank) {
    case 11:
      return "Jack";
    case 12:
      return "Queen";
    case 13:
      return "King";
    case 14:
      return "Ace";
    default:
      return rank;
  }
}

export function convertRankToLetter(rank) {
  switch(rank) {
    case 11:
      return "J";
    case 12:
      return "Q";
    case 13:
      return "K";
    case 14:
      return "A";
    default:
      return rank;
  }
}

export function getBestCardsString(i, playerResultArr) {
  let bestcards = ''
  if (playerResultArr[i].bestcardsArr) {
    for(let j in playerResultArr[i].bestcardsArr) {
      let obj = playerResultArr[i].bestcardsArr[j]
      bestcards+= convertRankToLetter(obj.rank) + convertHTMLSuit(obj.suit) + " "
    }
  }
  return bestcards
}

export function convertHTMLSuit(suit) {
  let htmlSuit = "<span style='color:black'>&spades;</span>";
  if (suit === 'heart') {
    htmlSuit = '<span style="color:red">&hearts;</span>';
  } else if (suit === 'diamond') {
    htmlSuit = '<span style="color:blue">&diams;</span>';
  } else if (suit === 'club') {
    htmlSuit = '<span style="color:green">&clubs;</span>';
  }
  return htmlSuit
}

export function getDeckArr() {
  let deckArr = this.createDeck();
  deckArr = this.shuffle(deckArr);
  return deckArr;
}

export const getHandRankObj = () => {
  return {
    highcard:1,
    pair:2,
    twopair:3,
    threeofakind:4,
    straight:5,
    flush:6,
    fullhouse:7,
    fourofakind:8,
    straightflush:9
  }
}

const handRankObj = getHandRankObj();

// table_click_winner calls setBestHand and pickWinningHand
export function setBestTexasHoldemHand(playerCardsArr, commCardsArr, street, playerBetTurn) {

  // console.log("setBestTexasHoldemHand++++++++++++++++++++++++++++++++++++++++++")
  // console.log(street, playerBetTurn)
  // console.log("playerCardsArr", JSON.stringify(playerCardsArr))
  // console.log("commCardsArr", JSON.stringify(commCardsArr))
  // console.log("------------------------------------------setBestTexasHoldemHand")

  let maxHandSize = 2
  if (street === 'flop') {
    maxHandSize = 5
  } else if (street === 'turn') {
    maxHandSize = 6
  } else if (street === 'river' || street === 'done') {
    maxHandSize = 7
  }

  let playerResultArr = []
  let activePlayerCardsArr = [];
  for (let playerIndex in playerCardsArr) {
    activePlayerCardsArr[playerIndex] = []
    activePlayerCardsArr[playerIndex].push(playerCardsArr[playerIndex][0])
    activePlayerCardsArr[playerIndex].push(playerCardsArr[playerIndex][1])

    playerResultArr = initPlayerResultRow(playerIndex, playerResultArr)

    if (street === 'preflop') {
      let cardOneRank = playerCardsArr[playerIndex][0]['rank']
      let cardTwoRank = playerCardsArr[playerIndex][1]['rank']
      if (cardOneRank === cardTwoRank) {
        playerResultArr[playerIndex].besthand = 'pair'
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'pair', true)
        playerResultArr[playerIndex].handrank = handRankObj.pair
        playerResultArr[playerIndex].pairrank = cardOneRank
        playerResultArr[playerIndex].bestcardsArr.push(playerCardsArr[playerIndex][0])
        playerResultArr[playerIndex].bestcardsArr.push(playerCardsArr[playerIndex][1])
      } else {
        playerResultArr[playerIndex].besthand = 'highcard'
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'highcard', true)
        playerResultArr[playerIndex].handrank = handRankObj.highcard
        playerResultArr[playerIndex].bestcardsArr.push(playerCardsArr[playerIndex][0])
        playerResultArr[playerIndex].bestcardsArr.push(playerCardsArr[playerIndex][1])
        if (cardOneRank > cardTwoRank) {
          playerResultArr[playerIndex].highcard = cardOneRank
        } else {
          playerResultArr[playerIndex].highcard = cardTwoRank
        }
      }
      // sort the cards desc
      playerResultArr[playerIndex].bestcardsArr.sort((a, b) => (a.rank > b.rank) ? -1 : 1)
      continue
    }

    if (activePlayerCardsArr[playerIndex].length === maxHandSize) {
      break
    }

    // different structure for community cards (appropriately), so merging community cards
    // into player's hole cards this way
    let j = 0;
    while (activePlayerCardsArr[playerIndex].length < maxHandSize) {
      activePlayerCardsArr[playerIndex].push(commCardsArr[j]);
      j++
    }
  }

  if (street === 'preflop') {
    return playerResultArr
  }

  playerResultArr = setPlayerResultArr(playerResultArr, activePlayerCardsArr)
  return playerResultArr;

}

function setPlayerResultArr(playerResultArr, activePlayerCardsArr) {

  playerResultArr = handleFlushAndStraightFlush(playerResultArr, activePlayerCardsArr)
  playerResultArr = handleStraight(playerResultArr, activePlayerCardsArr)
  playerResultArr = handlePairBasedHands(playerResultArr, activePlayerCardsArr)
  // if besthand did not get set above, set highcard
  playerResultArr = setHighCard(activePlayerCardsArr, playerResultArr)
  return playerResultArr
}

// init playerResultArr to hold objects to be filled later
function initPlayerResultRow(playerIndex, playerResultArr) {
  playerResultArr[playerIndex] = {}
  playerResultArr[playerIndex].bestcardsArr = []
  playerResultArr[playerIndex] = setHasObjPropsToFalse(playerResultArr[playerIndex])
  return playerResultArr
}

export function setBestCardsOfOneHand(commCardsArr, numCards) {
  // for the computer to check if the strength of the hand is in the community cards, not the hole cards
  let activePlayerCardsArr = []
  activePlayerCardsArr[0] = commCardsArr.slice(0, numCards)
  let playerResultArr = initPlayerResultRow(0, [])
  playerResultArr = setPlayerResultArr(playerResultArr, activePlayerCardsArr)
  return playerResultArr;
}

export function pickWinningHand(playerResultArr) {

  let tieBesthandRank = ''
  let tieHandrankArr = []
  let besthandRank = 0
  let besthandPlayerIndex
  // loop through all the cards and see who has the highest handrank
  // note, handrank is just highcard = 1, pair = 2, etc, so in this check, Ace highcard
  // will tie with King highcard, a pair of twos will tie with a pair of aces.
  // The actual value of the high card is done in the next block
  // see handRankObj for ranking of hands
  for(let playerIndex in playerResultArr) {
    let playerObj = playerResultArr[playerIndex]
    if (playerObj.handrank > besthandRank) {
      besthandRank = playerObj.handrank
      besthandPlayerIndex = playerIndex
      tieHandrankArr = []
      tieBesthandRank = ''
    } else if (playerObj.handrank === besthandRank) {
      tieBesthandRank = playerObj.besthand
      tieHandrankArr[playerIndex] = playerObj
      if (!tieHandrankArr[besthandPlayerIndex]) {
        tieHandrankArr[besthandPlayerIndex] = playerResultArr[besthandPlayerIndex]
      }
    }
  }

  // if a tie was found based on handrank (a pair of kings is equal to a pair of aces),
  // check the rank of the cards to determine winner, otherwise, set the winner
  if (!tieHandrankArr.length) {
    playerResultArr[besthandPlayerIndex].winner = 1
  } else {
    // compare each card in each hand
    // set a 'lost' property to the losing hand
    for (let playerOneIndex in tieHandrankArr) {
      if (tieHandrankArr[playerOneIndex].lost) {
        continue
      }

      for (let playerTwoIndex in tieHandrankArr) {
        if (tieHandrankArr[playerTwoIndex].lost || playerOneIndex === playerTwoIndex) {
          continue
        }

        if (tieBesthandRank === 'highcard' || tieBesthandRank === 'flush') {
          // Since a flush tie is determined by the highcard, test the tie here
          tieHandrankArr = highCardTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex)
        } else if (tieBesthandRank === 'pair') {
          tieHandrankArr = pairTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex)
        } else if (tieBesthandRank === 'twopair') {
          tieHandrankArr = twoPairTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex)
        } else if (tieBesthandRank === 'threeofakind') {
          tieHandrankArr = threeOfAKindTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex)
        } else if (tieBesthandRank === 'fourofakind') {
          tieHandrankArr = fourOfAKindTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex)
        } else if (tieBesthandRank === 'fullhouse') {
          tieHandrankArr = fullhouseTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex)
        } else if (tieBesthandRank === 'straight') {
          tieHandrankArr = straightTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex)
        } else if (tieBesthandRank === 'straightflush') {
          tieHandrankArr = straightFlushTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex)
        }

      }
    }
    playerResultArr = finalizeResults(playerResultArr, tieHandrankArr)

  }
  return playerResultArr
}


// Returns false if not a consecutive sequence of cards
// Returns array of 5 cards in descending sequence if rankArr contains a consecutive sequence of 5 cards
export function isSequential(rankArr, numCards) {

  if (typeof numCards === 'undefined') {
    numCards = 5
  }
  let sequentialArr = []
  let index = 0
  for(let rankIndex in rankArr) {
    index++
    rankIndex = parseInt(rankIndex)
    // the comparison is the current card against the previous card but
    // the first card has no previous card, so skip the first card
    if (rankIndex > 0) {
      let prevRank = parseInt(rankArr[rankIndex - 1]);
      let currentRank = parseInt(rankArr[rankIndex]);
      // 9,8,7,7,6,5 - skip if they're of the same rank
      if (prevRank === currentRank) {
        continue;
      }
      if (prevRank - 1 === currentRank) {
        if (!sequentialArr.includes(prevRank)) {
          sequentialArr.push(prevRank)
        }
        sequentialArr.push(currentRank)
      } else {
        // The test for being sequential failed, see if there are enough remaining cards
        // to make a straight. If so, start over.
        // (index - 1) because started with the first two cards and we now want
        // to drop the first card and start the sequence from the next card in line
        if (rankArr.length - (index - 1) < numCards) {
          break
        }
        sequentialArr = []
        sequentialArr.push(currentRank)
      }
      if (sequentialArr.length === numCards) {
        return sequentialArr
      }
    }
  }
  return false
}


// PRIVATE METHODS

// START HANDLE METHODS
// Set hand and related values in playerResult object aka playerResultArr[playerIndex]
//
function handleFlushAndStraightFlush(playerResultArr, activePlayerCardsArr) {

  // flush
  // count the number of each suit in the hand
  let suit = ''
  let numSuitedArr = []
  for (let playerIndex in activePlayerCardsArr) {
    numSuitedArr[playerIndex] = []
    for (let cardIndex in activePlayerCardsArr[playerIndex]) {
      // might be testing less than five cards
      // if (!activePlayerCardsArr[playerIndex][cardIndex] || !activePlayerCardsArr[playerIndex][cardIndex]['suit']) {
      //   continue
      // }
      suit = activePlayerCardsArr[playerIndex][cardIndex]['suit']
      if (!numSuitedArr[playerIndex][suit]) {
        numSuitedArr[playerIndex][suit] = 1
      } else {
        numSuitedArr[playerIndex][suit]++
      }
    }
  }

  // see if the suit has 5 or more cards of the same suit
  let flushSuitArr = []
  let suitArr = getSuitArr()
  for (let playerIndex in numSuitedArr) {
    for (let suit of suitArr) {
      let numSuited = numSuitedArr[playerIndex][suit]
      if (numSuited > 4 && !flushSuitArr[playerIndex]) {
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'flush', true)
        flushSuitArr[playerIndex] = {}
        flushSuitArr[playerIndex].suit = suit
        playerResultArr[playerIndex].besthand = 'flush'
        playerResultArr[playerIndex].flushsuit = suit
        playerResultArr[playerIndex].handrank = handRankObj.flush
        // build rankArr
        let rankArr = []
        let cardObjRankArr = []
        for (let cardIndex in activePlayerCardsArr[playerIndex]) {
          let currentCardSuit = activePlayerCardsArr[playerIndex][cardIndex]['suit']
          if (currentCardSuit === suit) {
            rankArr.push(parseInt(activePlayerCardsArr[playerIndex][cardIndex]['rank']))
            cardObjRankArr.push(activePlayerCardsArr[playerIndex][cardIndex])
          }
        }
        rankArr.sort((a, b) => b - a); // For descending sort
        cardObjRankArr.sort((a, b) => b.rank - a.rank); // For descending sort
        playerResultArr[playerIndex].rankArr = rankArr
        playerResultArr[playerIndex].cardObjRankArr = cardObjRankArr
      }
    }
  }
  // end flush

  // straight flush
  // check if flush is straight flush
  // select best 5 cards of flush
  for (let playerIndex in flushSuitArr) {
    if (hasObjPropIsTrue(playerResultArr[playerIndex], 'flush') === false) {
      continue
    }
    let rankArr = playerResultArr[playerIndex].rankArr
    let sequentialArr = isSequential(rankArr)
    if (isBabyStraightFlush(activePlayerCardsArr[playerIndex], playerResultArr[playerIndex].flushsuit)) {
      playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'straightflush', true)
      playerResultArr[playerIndex].besthand = 'straightflush'
      playerResultArr[playerIndex].highcardrank = 5
      playerResultArr[playerIndex].handrank = handRankObj.straightflush
      let currentSuit = playerResultArr[playerIndex].flushsuit
      playerResultArr[playerIndex].bestcardsArr.push({rank: 5, suit: currentSuit})
      playerResultArr[playerIndex].bestcardsArr.push({rank: 4, suit: currentSuit})
      playerResultArr[playerIndex].bestcardsArr.push({rank: 3, suit: currentSuit})
      playerResultArr[playerIndex].bestcardsArr.push({rank: 2, suit: currentSuit})
      playerResultArr[playerIndex].bestcardsArr.push({rank: 14, suit: currentSuit})
    } else {
      if (sequentialArr) {
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'straightflush', true)
        playerResultArr[playerIndex].handrank = handRankObj.straightflush
        playerResultArr[playerIndex].besthand = 'straightflush';
        // get five highest flush cards for both the straight flush
        let alreadySetArr = []
        let numSet = 0
        for (let i in sequentialArr) {
          for (let cardIndex in activePlayerCardsArr[playerIndex]) {
            let currentRank = activePlayerCardsArr[playerIndex][cardIndex]['rank']
            if (parseInt(i) === 0 && !playerResultArr[playerIndex].highcardrank) {
              playerResultArr[playerIndex].highcardrank = parseInt(currentRank)
            }
            let currentSuit = activePlayerCardsArr[playerIndex][cardIndex]['suit']
            if (sequentialArr[i] === currentRank && currentSuit === playerResultArr[playerIndex].flushsuit
                && !alreadySetArr.includes(cardIndex) && numSet < 5) {
              alreadySetArr.push(cardIndex)
              playerResultArr[playerIndex].bestcardsArr.push(activePlayerCardsArr[playerIndex][cardIndex])
              numSet++
            }
          }
        }
      } else {
        // get five highest cards for flush
        playerResultArr[playerIndex].highcardrank = parseInt(rankArr[0])
        playerResultArr[playerIndex].bestcardsArr = playerResultArr[playerIndex].cardObjRankArr.splice(0,5)
      }
    }
  }
  return playerResultArr
}

function handleStraight(playerResultArr, activePlayerCardsArr) {
  for(let playerIndex in activePlayerCardsArr) {
    if (hasObjPropIsTrue(playerResultArr[playerIndex], 'flush')
        || hasObjPropIsTrue(playerResultArr[playerIndex], 'straightflush')) {
      continue
    }
    let rankArr = []
    let sequentialArr = []
    for(let cardIndex in activePlayerCardsArr[playerIndex]) {
      rankArr.push(activePlayerCardsArr[playerIndex][cardIndex]['rank'])
    }
    rankArr.sort((a, b) => b - a); // For descending sort
    // manual check for baby straight
    if (isBabyStraight(rankArr)) {
      playerResultArr[playerIndex].besthand = 'straight'
      playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'straight', true);
      playerResultArr[playerIndex].highcardrank = 5
      playerResultArr[playerIndex].handrank = handRankObj.straight
      let aceObj = {}
      for(let cardIndex in activePlayerCardsArr[playerIndex]) {
        let currentRank = activePlayerCardsArr[playerIndex][cardIndex]['rank']
        if ([2,3,4,5].includes(currentRank)) {
          playerResultArr[playerIndex].bestcardsArr.push(activePlayerCardsArr[playerIndex][cardIndex])
        }
        playerResultArr[playerIndex].bestcardsArr.sort((a, b) => b.rank - a.rank);
        // set ace to appear last, since it is lowest ranked as 1 in a baby straight
        if (currentRank === 14) {
          aceObj = activePlayerCardsArr[playerIndex][cardIndex]
        }
      }
      playerResultArr[playerIndex].bestcardsArr.push(aceObj);
    } else {
      sequentialArr = isSequential(rankArr)
      if (sequentialArr) {
        playerResultArr[playerIndex].besthand = 'straight';
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'straight', true);
        playerResultArr[playerIndex].handrank = handRankObj.straight
        playerResultArr[playerIndex].bestcardsArr = []; // reset any previously set straight
        // get five highest cards in sequence
        let alreadySetArr = []
        let numSet = 0
        for (let i in sequentialArr) {
          for (let cardIndex in activePlayerCardsArr[playerIndex]) {
            let currentRank = activePlayerCardsArr[playerIndex][cardIndex]['rank']
            let alreadySet = alreadySetArr.includes(currentRank)
            if (sequentialArr[i] === currentRank && !alreadySet && numSet < 5) {
              alreadySetArr.push(currentRank)
              playerResultArr[playerIndex].bestcardsArr.push(activePlayerCardsArr[playerIndex][cardIndex])
              numSet++
              if (numSet === 5) {
                let highcardrank = parseInt(playerResultArr[playerIndex].bestcardsArr[0].rank)
                playerResultArr[playerIndex].highcardrank = highcardrank
                break
              }
            }
          }
        }
      }
    }
  }
  return playerResultArr
}

// determine if edge cases have been met where more than five cards make a hand and the best five cards
// have to be determined. eg 3 twopairs in one hand, select the best two pair
function handleEdgeCases(playerResultArr, playerIndex, rankMatchesArr, numRankMatchesObj) {

  for (let cardRank in numRankMatchesObj) {
    let numCardRankMatches = numRankMatchesObj[cardRank]
    if (numCardRankMatches === 4 && !hasObjPropIsTrue(playerResultArr[playerIndex], 'straightflush')) {
      playerResultArr[playerIndex] = setHasObjPropsToFalse(playerResultArr[playerIndex])
      playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'fourofakind', true)
    } else if (numCardRankMatches === 3
      && !hasObjPropIsTrue(playerResultArr[playerIndex], 'straight')
      && !hasObjPropIsTrue(playerResultArr[playerIndex], 'flush')
      && !hasObjPropIsTrue(playerResultArr[playerIndex], 'straightflush')) {
        if (!hasObjPropIsTrue(playerResultArr[playerIndex], 'threeofakind')) {
          playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'threeofakind', true)
        } else {
          playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'threeofakindtwice', true)
        }
    } else if (numCardRankMatches === 2
        && !hasObjPropIsTrue(playerResultArr[playerIndex], 'flush')
        && !hasObjPropIsTrue(playerResultArr[playerIndex], 'straight')
    ) {
      if (!hasObjPropIsTrue(playerResultArr[playerIndex], 'pair')
          && !hasObjPropIsTrue(playerResultArr[playerIndex], 'twopair')) {
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'pair', true)
      } else if (hasObjPropIsTrue(playerResultArr[playerIndex], 'pair')
          && !hasObjPropIsTrue(playerResultArr[playerIndex], 'twopair')) {
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'pair', false)
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'twopair', true)
      } else if (!hasObjPropIsTrue(playerResultArr[playerIndex], 'pair')
          && hasObjPropIsTrue(playerResultArr[playerIndex], 'twopair')) {
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'pair', false)
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'twopair', false)
        playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'threepair', true)
      }
    }
  }

  // handle (4 of a kind and a pair) or (4 of a kind and 3 of a kind) or (4 of a kind and 2 pair)
  // in one hand
  if (hasObjPropIsTrue(playerResultArr[playerIndex], 'fourofakind')
      && Object.keys(numRankMatchesObj).length > 1) {
    let keepFourOfAKindRank
    for (let i in rankMatchesArr) {
      let rank = rankMatchesArr[i].rank
      let num = numRankMatchesObj[rank]
      if (num === 4) {
        // we have 4 cards of the same rank matches which makes one 4 of a kind, set to keep in hand
        keepFourOfAKindRank = rank
        break
      }
    }

    // get rid of the other matches (either a pair or 3 of a kind or two pair)
    if (keepFourOfAKindRank) {
      let numCardsMatched
      let index = rankMatchesArr.length - 1
      do {
        let rank = rankMatchesArr[index].rank
        if (keepFourOfAKindRank !== rank) {
          rankMatchesArr.splice(index, 1)
          delete numRankMatchesObj[rank]
        }
        index--
        numCardsMatched = rankMatchesArr.length
      } while (numCardsMatched > 4)
    }

  } else if (hasObjPropIsTrue(playerResultArr[playerIndex], 'threeofakind')
      && hasObjPropIsTrue(playerResultArr[playerIndex], 'twopair')) {
    // fullhouse but made of three of a kind and two pair instead of just one pair
    // find the highest ranked pair of the pairs
    let highestRank = 0
    let delRankMatchesIndexArr = {}
    for (let rank in numRankMatchesObj) {
      if (numRankMatchesObj[rank] === 3) {
        // skip three of a kind and only evaluate pairs
        continue
      }
      rank = parseInt(rank)
      if (rank > highestRank) {
        highestRank = rank
      }
    }
    // delete the lowest ranked pair from the lookup array/objects
    for (let rank in numRankMatchesObj) {
      if (numRankMatchesObj[rank] === 3) {
        // skip three of a kind and only evaluate pairs
        continue
      }
      rank = parseInt(rank)
      if (rank < highestRank) {
        for (let i in rankMatchesArr) {
          let currentRank = rankMatchesArr[i].rank
          if (currentRank === rank) {
            delRankMatchesIndexArr[i] = rank
          }
        }
      }
      for (let rankMatchesIndex in delRankMatchesIndexArr) {
        let delRankMatchesIndex = parseInt(rankMatchesIndex)
        let delRank = delRankMatchesIndexArr[rankMatchesIndex]
        delete numRankMatchesObj[delRank]
        rankMatchesArr.splice(delRankMatchesIndex, 1)
      }

    }
    playerResultArr[playerIndex] = setHasObjPropsToFalse(playerResultArr[playerIndex])
    playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'pair', true)
    playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'threeofakind', true)
  } else if (hasObjPropIsTrue(playerResultArr[playerIndex], 'threeofakindtwice')) {
    // select the highest three of a kind and make the second into a pair
    // handle two sets of 3 of a kind for one player
    let pairRank
    let rankArr = Object.keys(numRankMatchesObj);
    let rankOne = parseInt(rankArr[0])
    let rankTwo = parseInt(rankArr[1])
    if (rankOne > rankTwo) {
      pairRank = rankTwo
    } else {
      pairRank = rankOne
    }
    numRankMatchesObj[pairRank]--
    playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'pair', true)
    playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'threeofakind', true)
    // make the second threeofakind into a pair as there is no such thing as two threeofakind
    let num = 0
    rankMatchesArr = rankMatchesArr.filter((rankMatchesObj) => {
      if (parseInt(rankMatchesObj.rank) === parseInt(pairRank)) {
        if (num < 2) {
          num++
          return rankMatchesObj
        }
      } else {
        return rankMatchesObj
      }
      return null
    })
    rankMatchesArr = rankMatchesArr.sort((a, b) => b.rank - a.rank);
  } else if (hasObjPropIsTrue(playerResultArr[playerIndex], 'threepair')) {
    // remove the lowest ranked pair of the three two pairs
    let lowestRank = 15
    for (let rank in numRankMatchesObj) {
      rank = parseInt(rank)
      if (rank < lowestRank) {
        lowestRank = rank
      }
    }
    // delete the lowest ranked pair from the lookup array/objects
    delete numRankMatchesObj[lowestRank]
    let delIndexArr = []
    for (let i in rankMatchesArr) {
      let currentRank = rankMatchesArr[i].rank
      if (currentRank === lowestRank) {
        delIndexArr.push(i)
        delete numRankMatchesObj[currentRank]
      }
    }
    let delIndex = delIndexArr.length - 1
    do {
      rankMatchesArr.splice(delIndexArr[delIndex], 1)
      delIndex--
    } while (delIndex >= 0)
    playerResultArr[playerIndex] = setHasObjPropsToFalse(playerResultArr[playerIndex])
    playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'twopair', true)
  }
  return playerResultArr
}

function handlePairBasedHands(playerResultArr, activePlayerCardsArr) {
  for (let playerIndex in activePlayerCardsArr) {
    if (hasObjPropIsTrue(playerResultArr[playerIndex], 'straightflush')) {
      continue
    }
    let numRankMatchesObj = {}
    let rankMatchesArr = []
    // compare each card in the player's hand to every other card in his hand and tally the matches
    let currentPlayerCardsArr = activePlayerCardsArr[playerIndex]
    // remember, we're not comparing the hole cards to the community cards, we're comparing activePlayerCards, ie
    // player's hole cards combined with community cards and then iterating over them all
    let alreadyMatchedArr = []
    for (let cardAIndex in currentPlayerCardsArr) {
      let cardAObj = currentPlayerCardsArr[cardAIndex]
      let cardARank = parseInt(cardAObj.rank)
      for (let cardBIndex in currentPlayerCardsArr) {
        if (cardAIndex === cardBIndex) {
          // don't compare a card to itself
          continue
        }
        let cardBObj = currentPlayerCardsArr[cardBIndex]
        let cardBRank = parseInt(cardBObj.rank)
        if (cardARank === cardBRank && !alreadyMatchedArr[cardBIndex]) {
          // Get the number of patterns matched eg. 1 pair = 1 match, 2 pair = 2 matches, full house = 2 matches,
          // 3 of a kind = 1 match, 4 of a kind equals 1 match
          if (!numRankMatchesObj[cardBRank]) {
            alreadyMatchedArr[cardBIndex] = 1
            numRankMatchesObj[cardBRank] = 1
            rankMatchesArr.push(cardBObj)
          } else {
            alreadyMatchedArr[cardBIndex] = 1
            numRankMatchesObj[cardBRank]++
            rankMatchesArr.push(cardBObj)
          }
        }
      }
    }

    // handle three two pairs, two sets of three of a kind and similar edge cases
    playerResultArr = handleEdgeCases(playerResultArr, playerIndex, rankMatchesArr, numRankMatchesObj)

    if (hasObjPropIsTrue(playerResultArr[playerIndex], 'pair')
        && hasObjPropIsTrue(playerResultArr[playerIndex], 'threeofakind')
    ) {
      playerResultArr[playerIndex] = setHasObjPropsToFalse(playerResultArr[playerIndex])
      playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'fullhouse', true)
    }

    // 2, 3 and 4 of a kind and fullhouse
    if (hasObjPropIsTrue(playerResultArr[playerIndex], 'fourofakind')) {
      playerResultArr[playerIndex].besthand = 'fourofakind'
      playerResultArr[playerIndex].handrank = handRankObj.fourofakind
      let fourofakindrank = parseInt(Object.keys(numRankMatchesObj)[0])
      playerResultArr[playerIndex].fourofakindrank = fourofakindrank
      playerResultArr[playerIndex] = setRankMatchesToBestCardsArr(rankMatchesArr, playerResultArr[playerIndex])
      playerResultArr[playerIndex] = fillBestCardsArr(playerResultArr[playerIndex], currentPlayerCardsArr, 'fourofakind')
    } else if (hasObjPropIsTrue(playerResultArr[playerIndex], 'fullhouse')) {
      let rankArr = Object.keys(numRankMatchesObj);
      let numMatchedA = numRankMatchesObj[parseInt(rankArr[0])]
      let numMatchedB = numRankMatchesObj[parseInt(rankArr[1])]
      playerResultArr[playerIndex].besthand = 'fullhouse';
      playerResultArr[playerIndex].handrank = handRankObj.fullhouse
      playerResultArr[playerIndex].bestcardsArr = []
      playerResultArr[playerIndex] = setRankMatchesToBestCardsArr(rankMatchesArr, playerResultArr[playerIndex])
      let bestcardsArr = playerResultArr[playerIndex].bestcardsArr;
      if (numMatchedA === 2 && numMatchedB === 3) {
        playerResultArr[playerIndex].threeofakindrank = parseInt(rankArr[1]);
        playerResultArr[playerIndex].pairrank = parseInt(rankArr[0]);
        // set the bestacardsArr to have larger matches first eg. fullhouse 3,3,3,7,7 not 3,7,3,7,3
        playerResultArr[playerIndex].bestcardsArr = bestcardsArr.sort((a,b) => b.rank - a.rank)
      } else if (numMatchedA === 3 && numMatchedB === 2) {
        playerResultArr[playerIndex].threeofakindrank = parseInt(rankArr[0])
        playerResultArr[playerIndex].pairrank = parseInt(rankArr[1])
        playerResultArr[playerIndex].bestcardsArr = bestcardsArr.sort((a,b) => a.rank - b.rank)
      }
    } else if (!hasObjPropIsTrue(playerResultArr[playerIndex], 'straight'
        && !hasObjPropIsTrue(playerResultArr[playerIndex], 'flush'))) {

      if (hasObjPropIsTrue(playerResultArr[playerIndex], 'threeofakind')) {
        playerResultArr[playerIndex].besthand = 'threeofakind'
        playerResultArr[playerIndex].handrank = handRankObj.threeofakind
        let threeofakindrank = parseInt(Object.keys(numRankMatchesObj)[0])
        playerResultArr[playerIndex].threeofakindrank = threeofakindrank
        playerResultArr[playerIndex] = setRankMatchesToBestCardsArr(rankMatchesArr, playerResultArr[playerIndex])
        playerResultArr[playerIndex] = fillBestCardsArr(playerResultArr[playerIndex], currentPlayerCardsArr, 'threeofakind')
      } else if (hasObjPropIsTrue(playerResultArr[playerIndex], 'twopair')) {
        let rankArr = Object.keys(numRankMatchesObj);
        let numMatchedA = numRankMatchesObj[parseInt(rankArr[0])]
        let numMatchedB = numRankMatchesObj[parseInt(rankArr[1])]
        if (numMatchedA === 2 && numMatchedB === 2) {
          playerResultArr[playerIndex].besthand = 'twopair';
          playerResultArr[playerIndex].handrank = handRankObj.twopair
          let rankOne = parseInt(rankArr[0])
          let rankTwo = parseInt(rankArr[1])
          if (rankOne > rankTwo) {
            playerResultArr[playerIndex].highpairrank = parseInt(rankArr[0])
            playerResultArr[playerIndex].lowpairrank = parseInt(rankArr[1])
          } else {
            playerResultArr[playerIndex].highpairrank = parseInt(rankArr[1])
            playerResultArr[playerIndex].lowpairrank = parseInt(rankArr[0])
          }
          playerResultArr[playerIndex] = setRankMatchesToBestCardsArr(rankMatchesArr, playerResultArr[playerIndex])
          playerResultArr[playerIndex] = fillBestCardsArr(playerResultArr[playerIndex], currentPlayerCardsArr, 'twopair')
        }
      } else if (hasObjPropIsTrue(playerResultArr[playerIndex], 'pair')) {
        playerResultArr[playerIndex].besthand = 'pair'
        let rankArr = Object.keys(numRankMatchesObj);
        let rank = parseInt(rankArr[0])
        playerResultArr[playerIndex].pairrank = rank
        playerResultArr[playerIndex].handrank = handRankObj.pair
        playerResultArr[playerIndex] = setRankMatchesToBestCardsArr(rankMatchesArr, playerResultArr[playerIndex])
        playerResultArr[playerIndex] = fillBestCardsArr(playerResultArr[playerIndex], currentPlayerCardsArr, 'pair')
      }
    }
  }
  return playerResultArr
}
//
// END HANDLE METHODS

function setHasObjPropsToFalse(playerResultObj) {
  playerResultObj.hasObj = {}
  playerResultObj.hasObj.straightflush = false;
  playerResultObj.hasObj.fourofakind = false;
  playerResultObj.hasObj.fullhouse = false;
  playerResultObj.hasObj.flush = false;
  playerResultObj.hasObj.straight = false;
  playerResultObj.hasObj.threeofakind = false;
  playerResultObj.hasObj.threeofakindtwice = false;
  playerResultObj.hasObj.threepair = false;
  playerResultObj.hasObj.twopair = false;
  playerResultObj.hasObj.pair = false;
  playerResultObj.hasObj.highcard = false;
  return playerResultObj
}

function hasObjPropIsTrue(playerResultObj, prop) {
  return playerResultObj.hasObj[prop]
}

function setHasObjProp(playerResultObj, prop, value) {
  playerResultObj.hasObj[prop] = value
  return playerResultObj
}

// Set the best aka highest ranked card available to any spaces available in the players bestcardsArr
function fillBestCardsArr(playerResultObj, currentPlayerCardsArr) {

  // these hands have 5 cards already, so just return
  if (hasObjPropIsTrue(playerResultObj, 'straightflush')
      || hasObjPropIsTrue(playerResultObj, 'flush')
      || hasObjPropIsTrue(playerResultObj, 'fullhouse')
      || hasObjPropIsTrue(playerResultObj, 'straight')
  ) {
    return playerResultObj
  }

  let remainingArr = []
  for(let j in currentPlayerCardsArr) {
    let hasBestCard = false
    for(let i in playerResultObj.bestcardsArr) {
      let bestCardObj = playerResultObj.bestcardsArr[i]
      // if the card is already in the bestcardsArr, don't add it again
      if (currentPlayerCardsArr[j].rank === bestCardObj.rank && currentPlayerCardsArr[j].suit === bestCardObj.suit) {
        hasBestCard = true
      }
    }
    if (!hasBestCard) {
      remainingArr.push(currentPlayerCardsArr[j])
    }
  }

  // sort the remaining cards desc and add as many as there are slots available in the hand
  remainingArr.sort((a, b) => (a.rank > b.rank) ? -1 : 1)
  playerResultObj.bestcardsArr.sort((a, b) => (a.rank > b.rank) ? -1 : 1)
  let maxToAdd = playerResultObj.bestcardsArr.length < 5 ? 5 - playerResultObj.bestcardsArr.length : 0
  for(let i = 0; i < maxToAdd; i++) {
    playerResultObj.bestcardsArr.push(remainingArr[i])
  }

  if (hasObjPropIsTrue(playerResultObj, 'pair')) {
    if (remainingArr[0]) {
      playerResultObj.pairkickeronerank = remainingArr[0].rank
    }
    if (remainingArr[1]) {
      playerResultObj.pairkickertworank = remainingArr[1].rank
    }
    if (remainingArr[2]) {
      playerResultObj.pairkickerthreerank = remainingArr[2].rank
    }
  } else if (hasObjPropIsTrue(playerResultObj, 'twopair')) {
    if (remainingArr[0]) {
      playerResultObj.twopairkickerrank = remainingArr[0].rank
    }
  } else if (hasObjPropIsTrue(playerResultObj, 'threeofakind')) {
    if (remainingArr[0]) {
      playerResultObj.threeofakindkickeronerank = remainingArr[0].rank
    }
    if (remainingArr[1]) {
      playerResultObj.threeofakindkickertworank = remainingArr[1].rank
    }
  } else if (hasObjPropIsTrue(playerResultObj, 'fourofakind')) {
    if (remainingArr[0]) {
      playerResultObj.fourofakindkickerrank = remainingArr[0].rank
    }
  }

  return playerResultObj

}

// 'matches' are a pair, two pair, 3 or quads
function setRankMatchesToBestCardsArr(rankMatchesArr, playerResultObj) {
  for (let i in rankMatchesArr) {
    playerResultObj.bestcardsArr.push(rankMatchesArr[i])
  }
  return playerResultObj
}

function setHighCard(activePlayerCardsArr, playerResultArr) {
  for(let playerIndex in activePlayerCardsArr) {
    if (!playerResultArr[playerIndex].besthand) {
      playerResultArr[playerIndex].besthand = 'highcard';
      playerResultArr[playerIndex] = setHasObjProp(playerResultArr[playerIndex], 'highcard', true)
      playerResultArr[playerIndex].handrank = handRankObj.highcard
      playerResultArr[playerIndex] = fillBestCardsArr(playerResultArr[playerIndex], activePlayerCardsArr[playerIndex])
    }
  }
  return playerResultArr
}

// START TIE DECIDER METHODS
//
function straightFlushTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex) {
  let playerOneObj = tieHandrankArr[playerOneIndex]
  let playerTwoObj = tieHandrankArr[playerTwoIndex]
  let playerTwoHighcardRank = playerTwoObj.highcardrank
  let playerOneHighcardRank = playerOneObj.highcardrank
  if (playerOneHighcardRank > playerTwoHighcardRank) {
    tieHandrankArr[playerTwoIndex].lost = 1
  } else if (playerOneHighcardRank < playerTwoHighcardRank) {
    tieHandrankArr[playerOneIndex].lost = 1
  }
  return tieHandrankArr
}

function fourOfAKindTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex) {
  let playerOneObj = tieHandrankArr[playerOneIndex]
  let playerTwoObj = tieHandrankArr[playerTwoIndex]
  let playerOneFourOfAKindKickerRank = playerOneObj.fourofakindkickerrank
  let playerTwoFourOfAKindKickerRank = playerTwoObj.fourofakindkickerrank
  if (playerOneFourOfAKindKickerRank > playerTwoFourOfAKindKickerRank) {
    tieHandrankArr[playerTwoIndex].lost = 1
  } else if (playerOneFourOfAKindKickerRank < playerTwoFourOfAKindKickerRank) {
    tieHandrankArr[playerOneIndex].lost = 1
  }
  return tieHandrankArr
}

function fullhouseTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex) {
  let playerOneObj = tieHandrankArr[playerOneIndex]
  let playerTwoObj = tieHandrankArr[playerTwoIndex]
  let playerTwoThreeOfAKindRank = playerTwoObj.threeofakindrank
  let playerOneThreeOfAKindRank = playerOneObj.threeofakindrank
  if (playerOneThreeOfAKindRank > playerTwoThreeOfAKindRank) {
    tieHandrankArr[playerTwoIndex].lost = 1
  } else if (playerOneThreeOfAKindRank < playerTwoThreeOfAKindRank) {
    tieHandrankArr[playerOneIndex].lost = 1
  } else if (playerOneThreeOfAKindRank === playerTwoThreeOfAKindRank) {
    let playerOneKickerPairRank = playerOneObj.pairrank
    let playerTwoKickerPairRank = playerTwoObj.pairrank
    if (playerOneKickerPairRank > playerTwoKickerPairRank) {
      tieHandrankArr[playerTwoIndex].lost = 1
    } else if (playerOneKickerPairRank < playerTwoKickerPairRank) {
      tieHandrankArr[playerOneIndex].lost = 1
    }
  }
  return tieHandrankArr
}

function straightTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex) {
  let playerOneObj = tieHandrankArr[playerOneIndex]
  let playerOneHighcard = playerOneObj.highcardrank
  let playerTwoObj = tieHandrankArr[playerTwoIndex]
  let playerTwoHighcard = playerTwoObj.highcardrank
  if (playerOneHighcard > playerTwoHighcard) {
    tieHandrankArr[playerTwoIndex].lost = 1
  } else if (playerOneHighcard < playerTwoHighcard) {
    tieHandrankArr[playerOneIndex].lost = 1
  }
  return tieHandrankArr
}

function threeOfAKindTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex) {
  let playerOneObj = tieHandrankArr[playerOneIndex]
  let playerOneThreeOfAKindRank = playerOneObj.threeofakindrank
  let playerTwoObj = tieHandrankArr[playerTwoIndex]
  let playerTwoThreeOfAKindRank = playerTwoObj.threeofakindrank
  if (playerOneThreeOfAKindRank > playerTwoThreeOfAKindRank) {
    tieHandrankArr[playerTwoIndex].lost = 1
  } else if (playerOneThreeOfAKindRank < playerTwoThreeOfAKindRank) {
    tieHandrankArr[playerOneIndex].lost = 1
  } else if (playerOneThreeOfAKindRank === playerTwoThreeOfAKindRank) {
    let playerOneKickerOneRank = playerOneObj.threeofakindkickeronerank
    let playerOneKickerTwoRank = playerOneObj.threeofakindkickertworank
    let playerTwoKickerOneRank = playerTwoObj.threeofakindkickeronerank
    let playerTwoKickerTwoRank = playerTwoObj.threeofakindkickertworank

    if (playerOneKickerOneRank > playerTwoKickerOneRank) {
      tieHandrankArr[playerTwoIndex].lost = 1
    } else if (playerOneKickerOneRank < playerTwoKickerOneRank) {
      tieHandrankArr[playerOneIndex].lost = 1
    } else if (playerOneKickerTwoRank > playerTwoKickerTwoRank) {
      tieHandrankArr[playerTwoIndex].lost = 1
    } else if (playerOneKickerTwoRank < playerTwoKickerTwoRank) {
      tieHandrankArr[playerOneIndex].lost = 1
    }
  }

  return tieHandrankArr
}

function twoPairTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex) {
  let playerOneObj = tieHandrankArr[playerOneIndex]
  let playerOneHighPairRank = playerOneObj.highpairrank
  let playerTwoObj = tieHandrankArr[playerTwoIndex]
  let playerTwoHighPairRank = playerTwoObj.highpairrank
  if (playerOneHighPairRank > playerTwoHighPairRank) {
    tieHandrankArr[playerTwoIndex].lost = 1
  } else if (playerOneHighPairRank < playerTwoHighPairRank) {
    tieHandrankArr[playerOneIndex].lost = 1
  } else if (playerOneHighPairRank === playerTwoHighPairRank) {
    let playerOneLowPairRank = playerOneObj.lowpairrank
    let playerTwoLowPairRank = playerTwoObj.lowpairrank
    if (playerOneLowPairRank > playerTwoLowPairRank) {
      tieHandrankArr[playerTwoIndex].lost = 1
    } else if (playerTwoLowPairRank > playerOneLowPairRank) {
      tieHandrankArr[playerOneIndex].lost = 1
    } else if (playerTwoLowPairRank === playerOneLowPairRank) {
      let playerOneTwoPairKickerRank = playerOneObj.twopairkickerrank
      let playerTwoTwoPairKickerRank = playerTwoObj.twopairkickerrank
      if (playerOneTwoPairKickerRank < playerTwoTwoPairKickerRank) {
        tieHandrankArr[playerOneIndex].lost = 1
      } else if (playerOneTwoPairKickerRank > playerTwoTwoPairKickerRank) {
        tieHandrankArr[playerTwoIndex].lost = 1
      }
    }
  }

  return tieHandrankArr
}

function pairTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex) {
  let playerOneObj = tieHandrankArr[playerOneIndex]
  let playerTwoObj = tieHandrankArr[playerTwoIndex]
  let playerOnePairRank = playerOneObj.pairrank
  let playerTwoPairRank = playerTwoObj.pairrank
  if (playerOnePairRank > playerTwoPairRank) {
    tieHandrankArr[playerTwoIndex].lost = 1
  } else if (playerOnePairRank < playerTwoPairRank) {
    tieHandrankArr[playerOneIndex].lost = 1
  } else if (playerOnePairRank === playerTwoPairRank) {
    let playerOneKickerOneRank = playerOneObj.pairkickeronerank
    let playerTwoKickerOneRank = playerTwoObj.pairkickeronerank
    let playerOneKickerTwoRank = playerOneObj.pairkickertworank
    let playerTwoKickerTwoRank = playerTwoObj.pairkickertworank
    let playerOneKickerThreeRank = playerOneObj.pairkickerthreerank
    let playerTwoKickerThreeRank = playerTwoObj.pairkickerthreerank
    if (playerOneKickerOneRank > playerTwoKickerOneRank) {
      tieHandrankArr[playerTwoIndex].lost = 1
    } else if (playerOneKickerOneRank< playerTwoKickerOneRank) {
      tieHandrankArr[playerOneIndex].lost = 1
    } else if (playerOneKickerOneRank === playerTwoKickerOneRank) {
      if (playerOneKickerTwoRank > playerTwoKickerTwoRank) {
        tieHandrankArr[playerTwoIndex].lost = 1
      } else if (playerOneKickerTwoRank < playerTwoKickerTwoRank) {
        tieHandrankArr[playerOneIndex].lost = 1
      } else if (playerOneKickerTwoRank === playerTwoKickerTwoRank) {
        if (playerOneKickerThreeRank > playerTwoKickerThreeRank) {
          tieHandrankArr[playerTwoIndex].lost = 1
        } else if (playerOneKickerThreeRank < playerTwoKickerThreeRank) {
          tieHandrankArr[playerOneIndex].lost = 1

        } else if (playerOneKickerThreeRank === playerTwoKickerThreeRank) {
          //
        }
      }
    }
  }
  return tieHandrankArr
}

function highCardTieDecider(tieHandrankArr, playerOneIndex, playerTwoIndex) {

  let playerOneObj = tieHandrankArr[playerOneIndex]
  let playerTwoObj = tieHandrankArr[playerTwoIndex]
  for (let i in playerOneObj.bestcardsArr) {
    let playerOneCardRank = playerOneObj.bestcardsArr[i].rank
    let playerTwoCardRank = playerTwoObj.bestcardsArr[i].rank
    if (playerOneCardRank > playerTwoCardRank) {
      tieHandrankArr[playerTwoIndex].lost = 1
      break
    } else if (playerOneCardRank < playerTwoCardRank) {
      tieHandrankArr[playerOneIndex].lost = 1
      break
    }
  }
  return tieHandrankArr
}
//
// END TIE DECIDER METHODS

// Set a final winner or set ties to remaining players
function finalizeResults(playerResultArr, tieHandrankArr) {
  // all hands without a 'lost' property set to it gets set in the finalArr
  let finalArr = []
  for(let playerIndex in tieHandrankArr) {
    if (!tieHandrankArr[playerIndex].lost) {
      let tmp = tieHandrankArr[playerIndex]
      finalArr[playerIndex] = tmp
    }
  }
  // more than one hand remaining results in a tie for each
  if (Object.keys(finalArr).length > 1) {
    for (let playerIndex in finalArr) {
      playerResultArr[playerIndex].tie = 1
    }
  } else {
    let playerIndex = Object.keys(finalArr)[0]
    playerResultArr[playerIndex].winner = 1
  }
  return playerResultArr
}

// Check for cards ace (14), 2, 3, 4, 5 and not a 6 (6,5,4,3,2 is not a baby straight and can be
// evaluated like a regular straight)
function isBabyStraight(rankArr) {
  if (rankArr.includes(14) && rankArr.includes(2) && rankArr.includes(3)
      && rankArr.includes(4) && rankArr.includes(5) && !rankArr.includes(6)) {
    return true
  }
  return false
}

// Check for cards ace (14), 2, 3, 4, 5 of the same suit and not a 6 of the same suit
// 6,5,4,3,2,Ace is not a baby straight and can be evaluated like a regular straight
function isBabyStraightFlush(playerCards, flushsuit) {

  let babyStraightArr = [14,2,3,4,5]
  let numSet = 0
  let hasASix = false
  for(let cardIndex in playerCards) {
    let currentCardSuit = playerCards[cardIndex]['suit']
    if (currentCardSuit === flushsuit && babyStraightArr.includes(playerCards[cardIndex]['rank'])) {
      numSet++
    } else if (currentCardSuit === flushsuit && playerCards[cardIndex]['rank'] === 6) {
      hasASix = true
    }
  }

  return numSet === 5 && hasASix === false

}

//
// function getSpecificMatchUpObj() {
//
//   let obj = {
//     aa_ak:[92,7],
//     aa_kk:[82,18],
//     aa_99:[81,18],
//     ak_aq:[68,28],
//     ak_tt:[43,57],
//     ak_22:[47,52],
//     aq_kj:[63,37],
//     aq_98:[63,37]
//
//   }
//
// }
//
// function getTypicalMatchUpObj() {
//
//   let obj = {
//     underPairVsOvercards:["Under Pair vs. Overcards",57,43],
//     overpairVsPair:["Overpair vs. Pair", 80,20],
//     twoOvercardsVsTwoUndercards:["Two Overcards vs. Two Undercards",63,37],
//     dominatedHand:["Dominated Hand",79,21],
//     veryDominatedHand:["Very Dominated Hand",89,11],
//     overcardVsDominatedKicker:["Overcard vs. Dominated Kicker",29,71],
//     pairVsOneOvercard:["Pair vs. One Overcard",69,31],
//     oneOverCardVsTwoMiddleCards:["One Overcard vs. Two Middle Cards", 57,43]
//   }
//
//   return obj;
//
// }
//
// function underPairVsOvercards() {
//   // get a random
//
// }
// function overpairVsPair() {
// }
// function twoOvercardsVsTwoUndercards() {
// }
// function dominatedHand() {
// }
// function veryDominatedHand() {
// }
// function overcardVsDominatedKicker() {
// }
// function oneOverCardVsTwoMiddleCards() {
// }
//
// export function selectRandomTypicalMatchUp() {
//
//   let obj = getTypicalMatchUpObj()
//   let keyArr = Object.keys(obj);
//   let rKey = Math.floor(Math.random() * Math.floor(keyArr.length));
//   console.log("L", keyArr[rKey]);
//
//
// }

