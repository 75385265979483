import React from 'react'
import Card from "./card";

export default function CommCards({commCardsArr, gameObj}) {

  let show = 0;
  let street = gameObj.street
  if (street === 'preflop') {
    show = 0;
  } else if (street === 'flop') {
    show = 3;
  } else if (street === 'turn') {
    show = 4;
  } else if (street === 'river' || street === 'done' ) {
    show = 5;
  }

  return (<div key={street} className={"commCardsCont"}>
    <div className='streetTitle'>Community Cards 	&middot; Street: <span className="street capitalize">{street}</span></div>
  {commCardsArr.map((cardObj, i) => {
    return <div className="commCardCont" key={'i_' + cardObj['rank'] + '_' + cardObj['suit']} >
      <Card
        cardObj={cardObj}
        show={(i < show ? true : false)}
        bgClass={""}
      />
      <div className="cb"></div>
    </div>
  })}
    <div className="cb"></div>
  </div>)

}
