import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer'

const initialState = {
    numRight:0,
    numWrong:0,
    score:0,
    scoreUpdate:0,
    scoreUpdateHand:'',
    message:'',
    numStraightFlush:0,
    numFourOfAKind:0,
    numFullhouse:0,
    numFlush:0,
    numStraight:0,
    numThreeOfAKind:0,
    numTwoPair:0,
    numPair:0,
    numHighCard:0,
    besthandForRound:'',
    previousBesthand:'',
    // isActive:1,
    gameOver:0,
    timesUp:0,
    tooManyWrong:0,
    restartTimerBool:false
}

export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    function setBestHandForRound(value) {
        dispatch({
            type: 'BESTHAND_FOR_ROUND',
            payload: value
        });
    };

    function setPreviousBesthand(value) {
        dispatch({
            type: 'PREVIOUS_BESTHAND',
            payload: value
        });
    };

    function setScore(value) {
        dispatch({
            type: 'SCORE',
            payload: value
        });
    };
    function setScoreUpdate(value) {
        dispatch({
            type: 'SCORE_UPDATE',
            payload: value
        });
    };
    function setScoreUpdateHand(value) {
        dispatch({
            type: 'SCORE_UPDATE_HAND',
            payload: value
        });
    };
    function setNumRight(value) {
        dispatch({
            type: 'NUM_RIGHT',
            payload: value
        });
    };

    function setNumWrong(value) {
        dispatch({
            type: 'NUM_WRONG',
            payload: value
        });
    };

    function setMessage(message) {
        dispatch({
            type: 'MESSAGE',
            payload: message
        });
    };

    function setGameOver(value) {
        dispatch({
            type: 'GAME_OVER',
            payload: value
        });
    };

    function setTimesUp(value) {
        dispatch({
            type: 'TIMES_UP',
            payload: value
        });
    };

    // function setToggleTimer(value) {
    //     dispatch({
    //         type: 'TOGGLE_TIMER',
    //         payload: value
    //     });
    // };

    function setTooManyWrong(value) {
        dispatch({
            type: 'TOO_MANY_WRONG',
            payload: value
        });
    };

    function playAgain() {
        dispatch({
            type: 'PLAY_AGAIN',
            payload: 1
        });
    }

    function setRestartTimerBool(value) {
        dispatch({
            type: 'RESTART_TIMER_BOOL',
            payload: value
        });
    }

    // START tally number of hands achieved
    function setNumStraightFlush(value) {
        dispatch({
            type: 'NUM_STRAIGHT_FLUSH',
            payload: value
        });
    }
    function setNumHighCard(value) {
        dispatch({
            type: 'NUM_HIGHCARD',
            payload: value
        });
    }
    function setNumPair(value) {
        dispatch({
            type: 'NUM_PAIR',
            payload: value
        });
    }
    function setNumTwoPair(value) {
        dispatch({
            type: 'NUM_TWOPAIR',
            payload: value
        });
    }
    function setNumThreeOfAKind(value) {
        dispatch({
            type: 'NUM_THREEOFAKIND',
            payload: value
        });
    }
    function setNumStraight(value) {
        dispatch({
            type: 'NUM_STRAIGHT',
            payload: value
        });
    }
    function setNumFlush(value) {
        dispatch({
            type: 'NUM_FLUSH',
            payload: value
        });
    }
    function setNumFullhouse(value) {
        dispatch({
            type: 'NUM_FULLHOUSE',
            payload: value
        });
    }
    function setNumFourOfAKind(value) {
        dispatch({
            type: 'NUM_FOUROFAKIND',
            payload: value
        });
    }
    // END tally number of hands achieved

    return (<GlobalContext.Provider value={{
        restartTimerBool:state.restartTimerBool,
        timesUp:state.timesUp,
        tooManyWrong:state.tooManyWrong,
        // isActive: state.isActive,
        numRight:state.numRight,
        numWrong:state.numWrong,
        score:state.score,
        scoreUpdate:state.scoreUpdate,
        scoreUpdateHand:state.scoreUpdateHand,
        message:state.message,
        gameOver:state.gameOver,
        numStraightFlush:state.numStraightFlush,
        numFourOfAKind:state.numFourOfAKind,
        numFullhouse:state.numFullhouse,
        numFlush:state.numFlush,
        numStraight:state.numStraight,
        numThreeOfAKind:state.numThreeOfAKind,
        numTwoPair:state.numTwoPair,
        numPair:state.numPair,
        numHighCard:state.numHighCard,
        besthandForRound:state.besthandForRound,
        previousBesthand:state.previousBesthand,
        setNumRight,
        setNumWrong,
        setScore,
        setScoreUpdate,
        setScoreUpdateHand,
        setMessage,
        setGameOver,
        setNumFourOfAKind,
        setNumFullhouse,
        setNumFlush,
        setNumStraight,
        setNumThreeOfAKind,
        setNumTwoPair,
        setNumPair,
        setNumHighCard,
        setNumStraightFlush,
        setBestHandForRound,
        setPreviousBesthand,
        // setToggleTimer,
        setTooManyWrong,
        setTimesUp,
        playAgain,
        setRestartTimerBool
    }}>
        {children}
    </GlobalContext.Provider>);
}
