import React, {useContext} from "react";
import {GlobalContext} from "../context/GlobalState";
let Base = require('../helpers/base.js');

export default function HandRankTally () {

  const {
    numStraightFlush,
    numFourOfAKind,
    numFullhouse,
    numFlush,
    numStraight,
    numThreeOfAKind,
    numTwoPair,
    numPair,
    numHighCard,
    besthandForRound,
    previousBesthand
  } = useContext(GlobalContext);

  let handRankObj = Base.getHandRankObj(besthandForRound)
  let besthandForRoundRank = handRankObj[besthandForRound]
  let previousBesthandRank = previousBesthand ? handRankObj[previousBesthand] : 0

  let straightFlushEffectStyleObj = {}
  let fourOfAKindEffectStyleObj = {}
  let fullHouseEffectStyleObj = {}
  let flushEffectStyleObj = {}
  let straightEffectStyleObj = {}
  let threeOfAKindEffectStyleObj = {}
  let twoPairEffectStyleObj = {}
  let pairEffectStyleObj = {}
  let highCardEffectStyleObj = {}
  let doEffect = besthandForRoundRank > previousBesthandRank
  // console.log("besthandForRound", besthandForRound, besthandForRoundRank)
  // console.log("previousBesthand", previousBesthand, previousBesthandRank)
  // console.log("doEffect", doEffect)
  let effectStyleObj = {backgroundColor:'#8ba9d6'}
  if (doEffect && besthandForRound === 'highcard') {
    highCardEffectStyleObj = effectStyleObj
  } else if (doEffect && besthandForRound === 'pair') {
    pairEffectStyleObj = effectStyleObj
  } else if (doEffect && besthandForRound === 'twopair') {
    twoPairEffectStyleObj = effectStyleObj
  } else if (doEffect && besthandForRound === 'threeofakind') {
    threeOfAKindEffectStyleObj = effectStyleObj
  } else if (doEffect && besthandForRound === 'straight') {
    straightEffectStyleObj = effectStyleObj
  } else if (doEffect && besthandForRound === 'flush') {
    flushEffectStyleObj = effectStyleObj
  } else if (doEffect && besthandForRound === 'fullhouse') {
    fullHouseEffectStyleObj = effectStyleObj
  } else if (doEffect && besthandForRound === 'fourofakind') {
    fourOfAKindEffectStyleObj = effectStyleObj
  } else if (doEffect && besthandForRound === 'straightflush') {
    straightFlushEffectStyleObj = effectStyleObj
  }

  return <div className="handRankTallyCont">
    <div className="handRankTally" style={straightFlushEffectStyleObj}>Strt Flush:{numStraightFlush}</div>
    <div className="handRankTally handRankTallyFourOfAKind" style={fourOfAKindEffectStyleObj}>4 kind:{numFourOfAKind}</div>
    <div className="handRankTally handRankTallyFullHouse" style={fullHouseEffectStyleObj}>FullHouse:{numFullhouse}</div>
    <div className="handRankTally handRankTallyFlush" style={flushEffectStyleObj}>Flush:{numFlush}</div>
    <div className="handRankTally handRankTallyStraight" style={straightEffectStyleObj}>Strt:{numStraight}</div>
    <div className="handRankTally handRankTallyThreeOfAKind" style={threeOfAKindEffectStyleObj}>3 Kind:{numThreeOfAKind}</div>
    <div className="handRankTally handRankTallyTwoPair" style={twoPairEffectStyleObj}>2 Pair:{numTwoPair}</div>
    <div className="handRankTally handRankTallyPair" style={pairEffectStyleObj}>Pair:{numPair}</div>
    <div className="handRankTally handRankTallyHighCard" style={highCardEffectStyleObj}>High Card:{numHighCard}</div>
    <div className="cb"></div>
    {/*<div className="msg">{message}</div>*/}
    {/*<div className="cb"></div>*/}
  </div>

}
