import React, { useEffect, useState, useContext } from 'react'
import Hand from './hand.js'
import { GlobalContext } from '../context/GlobalState';
import CommCards from '../components/comm_cards.js'
let Base = require('../helpers/base.js');
//let Mock = require('../helpers/mock_hands.js');

// Present the player's hole cards and have the user click on the winning cards for that
// turn (pre-flop thru river)

export default function TableTrainer ({nextHand, deckArr, numHands, levelObj}) {

  const {
    numWrong,
    gameOver,
    setScore,
    setScoreUpdate,
    setScoreUpdateHand,
    setNumWrong,
    setNumRight,
    setGameOver,
    setNumFourOfAKind,
    setNumFullhouse,
    setNumFlush,
    setNumStraight,
    setNumThreeOfAKind,
    setNumTwoPair,
    setNumPair,
    setNumHighCard,
    setNumStraightFlush,
    setBestHandForRound,
    setPreviousBesthand,
    setTooManyWrong
  } = useContext(GlobalContext);

  const [bestHandBynumHandsArr, setBestHandBynumHandsArr] = useState([]);
  const [showNextStreetBtn, setShowNextStreetBtn] = useState(0);
  const [showNextHandBtn, setShowNextHandBtn] = useState(0);

  const [msg, setMsg] = useState('');
  let [stage, setStage] = useState('');

  // MOCK
  // more than 1 player may need more data added to mock_hands.js if you get the
  // TypeError: Cannot read property 'map' of undefined in hand.js
  // let mockObj = Mock.getHands('threeofakind')
  // let [playerCardsArr] = useState(mockObj.playerCardsArr);
  // let [commCardsArr] = useState(mockObj.commCardsArr);
  // let numPlayers = playerCardsArr.length;
  // let numPlayersArr=[];let i=0;while(numPlayersArr.push(i++) < numPlayers);
  // END MOCK

  // NON-MOCK
  let numPlayers = levelObj.numPlayers
  let numPlayersArr=[];let i=0;while(numPlayersArr.push(i++) < numPlayers);
  let initPlayerCardsArr = []
  for(let i = 0; i < numPlayers; i++) {
    initPlayerCardsArr[i] = deckArr.splice(0, 2);
  }
  let [playerCardsArr] = useState(initPlayerCardsArr.splice(0,numPlayers));
  let initCommCardsArr = deckArr.splice(0,5);
  let [commCardsArr] = useState(initCommCardsArr);
  // END NON-MOCK

  let hiddenHandResultArr = []
  for(let i = 0; i < numPlayers; i++) {
    hiddenHandResultArr.push({visibility:'hidden'})
  }
  let [handResultTextStyleArr, setHandResultTextStyleArr] = useState(hiddenHandResultArr);
  let [handResultContStyle, setHandResultContStyle] = useState({visibility:'hidden'});

  let [playerResultArr, setPlayerResultArr] = useState({});

  const doNextHand = () => {
    setBestHandForRound('')
    setPreviousBesthand('')
    nextHand()
  }

  const nextStreet = () => {
    setMsg('')
    setHandResultContStyle({visibility: 'hidden'})
    setHandResultTextStyleArr(hiddenHandResultArr);
    setShowNextStreetBtn(0)
    if (!stage) {
      stage = 'preflop'
      setStage(stage)
    } else if (stage === 'preflop') {
      stage = 'flop'
      setStage(stage)
    } else if (stage === 'flop') {
      stage = 'turn'
      setStage(stage)
    } else if (stage === 'turn') {
      stage = 'river'
      setStage(stage)
    } else if (stage === 'river') {
      stage = 'done'
      setStage(stage)
    }
    if (stage !== 'done') {
      playerResultArr = Base.setBestTexasHoldemHand(playerCardsArr, commCardsArr, stage)
      Base.pickWinningHand(playerResultArr, stage)
      setPlayerResultArr(playerResultArr)
    }
  }

  useEffect(() => {
    if (!stage) {
      nextStreet()
    }
  }, [stage, nextStreet]);

  // multiply the hand rank number by the value returned to get the score value of said hand
  const getScoreMultiplierForHand = (hand) => {
    if (hand === 'straightflush') {
      return 90
    } else if (hand === 'fourofakind') {
      return 80
    } else if (hand === 'fullhouse') {
      return 70
    } else if (hand === 'flush') {
      return 60
    } else if (hand === 'straight') {
      return 50
    } else if (hand === 'threeofakind') {
      return 40
    } else if (hand === 'twopair') {
      return 30
    } else if (hand === 'pair') {
      return 20
    } else if (hand === 'highcard') {
      return 10
    }
  }

  const checkAnswer = (playerIndex, playerResultArr) => {
    if (showNextStreetBtn) {
      setMsg("Click the 'Next Street' button to proceed.")
      return
    } else if (showNextHandBtn) {
      setMsg("Click the 'Next Hand' button to proceed.")
      return
    } else if (gameOver) {
      setMsg("Game is over.")
      return
    }
    // hide all hand result text. (handResultText is 'pair', 'twopair', etc)
    let tmpHiddenHandResultArr = {...hiddenHandResultArr}
    for(let j in playerResultArr) {
      // display the outcome for the hole cards clicked on as well as winner or tie
      if (parseInt(playerIndex) === parseInt(j) || playerResultArr[j].winner || playerResultArr[j].tie) {
        tmpHiddenHandResultArr[j] = {visibility: 'visible'}
      }
    }
    setHandResultTextStyleArr(tmpHiddenHandResultArr);

    setHandResultContStyle({visibility: 'visible'})
    if (stage === 'river') {
      setShowNextHandBtn(1)
      setStage('done')
    } else {
      setShowNextStreetBtn(1)
    }
    let playerResultObj = playerResultArr[playerIndex]
    if (playerResultObj.winner || playerResultObj.tie) {
      setNumRight(1)
      // START set best hand for round
      let handRankObj = Base.getHandRankObj()
      let besthandForRound = bestHandBynumHandsArr[numHands] ? bestHandBynumHandsArr[numHands] : false
      let currentBesthandForStreet = playerResultObj.besthand
      let besthandForRoundRank = besthandForRound ? parseInt(handRankObj[besthandForRound]) : 0
      let currentBesthandForStreetRank = parseInt(handRankObj[currentBesthandForStreet])
      let currentBestHandForStreetIsBest = currentBesthandForStreetRank > besthandForRoundRank
      if (!besthandForRound || currentBestHandForStreetIsBest) {
        let bestHandBynumHandsArr = []
        bestHandBynumHandsArr[numHands] = currentBesthandForStreet
        setBestHandBynumHandsArr(bestHandBynumHandsArr)
        setBestHandForRound(currentBesthandForStreet)
        setPreviousBesthand(besthandForRound)
      }
      // END set best hand for round

      // compare 'current best hand' to 'best hand for round' and if current is better, log it
      let scoreUpdate = 0
      let scoreMultiplier = getScoreMultiplierForHand(currentBesthandForStreet)
      if (currentBesthandForStreet === 'straightflush' && currentBestHandForStreetIsBest) {
        setNumStraightFlush(1)
        scoreUpdate = currentBesthandForStreetRank * scoreMultiplier
      } else if (currentBesthandForStreet === 'fourofakind' && currentBestHandForStreetIsBest) {
        setNumFourOfAKind(1)
        scoreUpdate = currentBesthandForStreetRank * scoreMultiplier
      } else if (currentBesthandForStreet === 'fullhouse' && currentBestHandForStreetIsBest) {
        setNumFullhouse(1)
        scoreUpdate = currentBesthandForStreetRank * scoreMultiplier
      } else if (currentBesthandForStreet === 'flush' && currentBestHandForStreetIsBest) {
        setNumFlush(1)
        scoreUpdate = currentBesthandForStreetRank * scoreMultiplier
      } else if (currentBesthandForStreet === 'straight' && currentBestHandForStreetIsBest) {
        setNumStraight(1)
        scoreUpdate = currentBesthandForStreetRank * scoreMultiplier
      } else if (currentBesthandForStreet === 'threeofakind' && currentBestHandForStreetIsBest) {
        setNumThreeOfAKind(1)
        scoreUpdate = currentBesthandForStreetRank * scoreMultiplier
      } else if (currentBesthandForStreet === 'twopair' && currentBestHandForStreetIsBest) {
        setNumTwoPair(1)
        scoreUpdate = currentBesthandForStreetRank * scoreMultiplier
      } else if (currentBesthandForStreet === 'pair' && currentBestHandForStreetIsBest) {
        setNumPair(1)
        scoreUpdate = currentBesthandForStreetRank * scoreMultiplier
      } else if (currentBesthandForStreet === 'highcard' && currentBestHandForStreetIsBest) {
        setNumHighCard(1)
        scoreUpdate = currentBesthandForStreetRank * scoreMultiplier
      }
      setScore(scoreUpdate)
      setScoreUpdate(scoreUpdate)
      setScoreUpdateHand(currentBesthandForStreet)
    } else {
      // WRONG
      setNumWrong(1)
      if (numWrong > 4) {
        setGameOver(1)
        setTooManyWrong(1)
        setShowNextHandBtn(0)
        setShowNextStreetBtn(0)
      }

      // get winning hand and deduct from score
      let value = 0
      for(let playerObj of playerResultArr) {
        if (playerObj.winner || playerObj.tie) {
          let scoreMultiplier = getScoreMultiplierForHand(playerObj.besthand)
          let handRankObj = Base.getHandRankObj()
          let rank = handRankObj[playerObj.besthand]
          value = rank * -1 * scoreMultiplier
          setScoreUpdateHand(playerObj.besthand)
          setScoreUpdate(value)
          setScore(value)
          break
        }
      }
    }
  }

  const getHand = (i) => {
    let handValue = ''
    let bestcards = ''
    let resultClassName = 'handTwoCardsClickWinnerOddCont'
    if (i % 2 === 0) {
      resultClassName = 'handTwoCardsClickWinnerEvenCont'
    }
    let handResultText = ''
    if (playerResultArr[i]) {
      if (playerResultArr[i].besthand) {
        handValue = playerResultArr[i].besthand;
      }
      if (playerResultArr[i].bestcardsArr) {
        for(let j in playerResultArr[i].bestcardsArr) {
          let obj = playerResultArr[i].bestcardsArr[j]
          bestcards+= Base.convertRankToLetter(obj.rank) + Base.convertHTMLSuit(obj.suit) + " "
        }
      }
      if (playerResultArr[i].winner) {
        handResultText = ' <div class="handResultText" style="background-color:green;">&#x2713;</div>'
      } else if (playerResultArr[i].tie) {
        handResultText = ' <div class="handResultText"  style="background-color:blue"><span style="color:#ffffff">TIED</span></div>'
      } else {
        handResultText = ' <div class="handResultText"  style="background-color:red">&#x2717;</div>'
      }
    }
    return <div
        onClick={() => checkAnswer(i, playerResultArr)}
        className={resultClassName}>
      <Hand
        handArr={playerCardsArr[i]}
        playerIndex={i}
        show={true}
      /><br />
      <div className="cb"></div>
      <div className='cardResultCont' style={handResultContStyle}>

        <div className='handValue'>{handValue}
          <div style={handResultTextStyleArr[i]} dangerouslySetInnerHTML={{ __html: handResultText}} />
        </div>
        <div className='bestCards' dangerouslySetInnerHTML={{ __html: bestcards}} />
      </div>

    </div>
  }

  const getCommCards = (stage) => {
    return <CommCards commCardsArr={commCardsArr} gameObj={{street:stage}}/>
  }

  let nextHandBtnStyle = showNextHandBtn ? {display:'block'} : {display:'none'};
  let nextStreetBtnStyle = showNextStreetBtn ? {display:'block'} : {display:'none'};

  let width = numPlayers < 5 ? numPlayers * 134 : numPlayers * 134 / 2
  let cardsTableContStyle = {width:width+"px"};

  return <div className="tableClickWinner" key={stage}>
    <div className="cb"></div>
      <div className='cardsTableCont' style={cardsTableContStyle}>
      {numPlayersArr.map(i => {
        return <div key={i}>{getHand(i)}</div>
      })}
      <div className="cb"></div>
    </div>
    <div key={i}><div className='commCards'>{getCommCards(stage)}</div></div>
    <div className="gameFooterCont">
      <button
          className='trainerNextHandBtn'
          style={nextHandBtnStyle}
          onClick={() => doNextHand('clickwinner')}>Next hand</button>
      <button
          className='nextStreetBtn'
          style={nextStreetBtnStyle}
          onClick={() => nextStreet()}>Next street</button>
      <div className='msg'>{msg}</div>
      {/*<div className="footerInstructions">Click on the hole cards (the set of two cards) that either alone or when combined with the*/}
      {/*  community cards make the best poker hand from the best five cards.*/}
      {/*</div>*/}
      <div className="cb"></div>
    </div>
  </div>

}