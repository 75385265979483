import React, { useContext } from 'react'
import { GlobalContext } from '../context/GlobalState'
import { Link } from "react-router-dom";

const GameOver = ({ reset }) => {

  const {
    gameOver,
    timesUp,
    tooManyWrong,
    numWrong,
    playAgain
  } = useContext(GlobalContext)

  function doOver () {
    reset()
    playAgain()
  }

  let key = gameOver + '_' + timesUp + '_' + tooManyWrong + '_'
  let styleObj = gameOver ? { display: 'block' } : { display: 'none' }
  let msg = numWrong + ' wrong! Game Over!'
  if (timesUp) {
    msg = 'Time ran out!'
  }

  if (!gameOver) {
    return null
  }

  return <div key={key} className="gameOverCont" style={styleObj}>

    <div className="gameOverMsg">
      {msg}
    </div>
    <div className="gameOverBtnCont">
      <div className="gameOverBtnLinkLeft" onClick={() => doOver()}>
        Play Again
      </div>
      <Link
        to={"/intro"}
        className="gameOverBtnLinkRight"
      >Homepage</Link>
      <div className='cb'></div>
    </div>
  </div>

}

export default GameOver