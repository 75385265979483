const Base = require('../helpers/base.js');

export default function Card ({cardObj, show, bgClass}) {

  let rank = cardObj['rank'];
  let suit = cardObj['suit'];

  let displayed = 'cover';
  if (show) {
      displayed = 'face';
  }

  let key =  rank + "_" + suit + "_" + displayed;

  let displayCard = '';
  if (displayed === 'cover') {
      displayCard = '<img class="cardCover" src="logo192.png" />';
  } else {
      let rankDisplay = Base.convertRankToFullName(rank);
      let htmlSuit = Base.convertHTMLSuit(suit);
      displayCard = '<div class="rankDisplay">' + rankDisplay + '</div> ' + '<div class="suitSymbolBigCard">' + htmlSuit + '</div>';
  }

  return <div key={key} className={`card ${bgClass}`}>
      <div dangerouslySetInnerHTML={{ __html: displayCard}} />
      <div className="cb"></div>
  </div>

}
