import React, {useContext} from "react";
import {GlobalContext} from "../context/GlobalState";
import Timer from "../components/timer";

export default function Scoreboard ({numHands, currentLevel, maxSeconds}) {

  let { numWrong, numRight, score, scoreUpdate, scoreUpdateHand } = useContext(GlobalContext);
  let scoreUpdateStyle = {color:'green'}
  if (scoreUpdate < 0) {
    scoreUpdateStyle = {color:'red'}
  }
  if (scoreUpdate === 0) {
    scoreUpdate = ''
  } else if (scoreUpdate > 0) {
    scoreUpdate = "+" + scoreUpdate + " " + scoreUpdateHand
  } else {
    scoreUpdate = scoreUpdate + " " + scoreUpdateHand
  }

  return <div className="trainerScoreContainer" key={numHands}>
    <div className="homeIconCont"><a
        href='/'><img
        className="homeIconImg"
        src='/home-outline_black_50x50.png'
        alt='Home Page'
      /></a>
    </div>
    <div className="level">Level:{currentLevel}</div>
    <div className="numHands">Hand #:{numHands}</div>
    <div className="scoreCorrect">Right:{numRight}</div>
    <div className="scoreWrong">Wrong:{numWrong}</div>
    <div className="score"><span>Score:{score}</span>
      <span className="scoreUpdate" style={scoreUpdateStyle}>{scoreUpdate}</span>
    </div>
    <Timer
        maxSeconds={maxSeconds}
        currentLevel = {currentLevel}
        numHands = {numHands}
    />
    <div className="cb"></div>

  </div>

}
